<!-- eslint-disable prettier/prettier -->
<template>
  <div>
    <v-row
      v-if="this.edit != true"
      :class="period == 1 ? 'pt-1' : ''"
      class="mx-0 my-0 py-0 px-0"
    >
      <v-col
        cols="12"
        :class="this.noMargins == true ? 'mx-0 px-0' : ''"
        v-if="this.contract_data[0].plan > 0"
      >
        <form-textbox
          :field_key="'power_price'"
          :period="periods"
          :error="error"
          :title="'Indique o custo da potência em horas de ponta'"
          :field_type="'float'"
          :suffix="'€ / kVA'"
          :bold="true"
          :required="true"
          @warning="watchWarning"
          :title_alone="false"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- Simples tariff value 1 -->
      <v-col
        cols="12"
        :class="
          (this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null)
        "
        class="pt-2 pb-0"
        v-if="
          this.contract_data[0].schedule_type == 0 &&
          this.contract_data[0].plan == 0
        "
      >
        <form-textbox
          :field_key="'t2_energy_price'"
          :period="periods"
          :title="'Indique o valor da tarifa de energia'"
          :placeholder="'Valor da tarifa'"
          :error="error"
          :required="true"
          :field_type="'float'"
          @warning="watchWarning"
          :suffix="'€ / kWh'"
          :bold="true"
          :title_alone="false"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- End simples tariff value 1 -->

      <!-- Title for Bi-horario, Tri-horario and !BTN value -->
      <v-col
        cols="12"
        :class="
          (this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null)
        "
        class="pt-2 pb-4"
        v-if="
          this.contract_data[0].schedule_type > 0 &&
          this.contract_data[0].plan != 1
        "
      >
        <form-textbox
          :bold="true"
          :error="error"
          :period="periods"
          :title_alone="true"
          :required="true"
          @warning="watchWarning"
          :title="'Indique o valor das tarifas de Energia'"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- End title for Bi-horario and Tri-horario and !BTN value -->

      <!-- Bi-horario, Tri-horario and !BTN tariff values-->
      <v-col :class="this.noMargins == true ? 'px-0 pb-0' : 'pb-0'">
        <v-row>
          <v-col
            :class="this.noMargins == true ? 'mx-0' : ''"
            cols="12"
            v-if="this.contract_data[0].plan == 1"
            class="pt-0 pb-2 normal-label text-5"
            >Indique o valor das tarifas de Energia</v-col
          >
          <v-col
            cols="6"
            class="pt-4 pb-0"
            :class="this.noMargins == true ? 'mx-0' : ''"
            v-if="this.contract_data[0].plan > 0"
          >
            <form-textbox
              :field_key="'t1_energy_price'"
              :period="periods"
              :title="'Hora de Super-Vazio'"
              :error="error"
              @warning="watchWarning"
              :required="true"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              (this.contract_data[0].plan == 0 &&
                this.contract_data[0].schedule_type == 2) ||
              this.contract_data[0].plan == null ||
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            class="pt-4 pb-0"
            v-if="
              this.contract_data[0].schedule_type != 0 ||
              this.contract_data[0].plan != 0
            "
          >
            <form-textbox
              :field_key="'t2_energy_price'"
              :error="error"
              :period="periods"
              :title="'Hora de Vazio'"
              :required="true"
              @warning="watchWarning"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              (this.contract_data[0].plan == 0 &&
                this.contract_data[0].schedule_type == 2) ||
              this.contract_data[0].plan == null ||
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            class="pt-4 pb-0"
            v-if="
              this.contract_data[0].schedule_type > 0 ||
              this.contract_data[0].plan != 0
            "
          >
            <form-textbox
              :field_key="'t3_energy_price'"
              :period="periods"
              :error="error"
              :title="
                this.contract_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio'
              "
              :field_type="'float'"
              :required="true"
              :suffix="'€ / kWh'"
              @warning="watchWarning"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              (this.contract_data[0].plan == 0 &&
                this.contract_data[0].schedule_type == 2) ||
              this.contract_data[0].plan == null ||
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            class="pt-4 pb-0"
            v-if="
              this.contract_data[0].schedule_type == 2 ||
              this.contract_data[0].plan != 0
            "
          >
            <form-textbox
              :period="periods"
              :error="error"
              :field_key="'t4_energy_price'"
              :title="'Hora de Ponta'"
              @warning="watchWarning"
              :required="true"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
        </v-row>
        <v-row v-if="this.contract_data[0].plan == 1">
          <v-col
            :class="this.noMargins == true ? 'mx-0' : ''"
            cols="12"
            v-if="this.contract_data[0].plan == 1"
            class="pt-0 pb-2 normal-label text-5 mt-3"
            >Indique o valor das tarifas de Redes</v-col
          >
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-4' : ''">
            <form-textbox
              :period="periods"
              :error="error"
              :field_key="'t1_grid_price'"
              @warning="watchWarning"
              :title="'Hora de Super-Vazio'"
              :field_type="'float'"
              :required="true"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0 pt-4' : '',
              (this.contract_data[0].plan == 0 &&
                this.contract_data[0].schedule_type == 2) ||
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
          >
            <form-textbox
              :period="periods"
              :error="error"
              :field_key="'t2_grid_price'"
              :title="'Hora de Vazio'"
              :field_type="'float'"
              :required="true"
              @warning="watchWarning"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-0' : ''">
            <form-textbox
              :period="periods"
              :field_key="'t3_grid_price'"
              :title="
                this.contract_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio'
              "
              :field_type="'float'"
              :error="error"
              @warning="watchWarning"
              :required="true"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-0' : ''">
            <form-textbox
              :period="periods"
              :field_key="'t4_grid_price'"
              :error="error"
              :title="'Hora de Ponta'"
              @warning="watchWarning"
              :field_type="'float'"
              :required="true"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <!-- <v-col cols="12" :class="this.noMargins == true ? 'mx-0' : ''">
          <form-textbox
            :period="periods"
            :field_key="'mec_ajuste'"
            :title="'Indique o valor da tarifa de “Mecanismo de Ajuste“ '"
            :field_type="'float'"
            :suffix="'€ / kWh'"
            :bold="true"
            :title_alone="false"
            @checkMethod="checkSubmitButton"
          />
        </v-col>
        <v-col cols="12" :class="this.noMargins == true ? 'mx-0' : ''">
          <form-textbox
            :period="periods"
            :field_key="'banda_reg'"
            :title="'Indique o valor da tarifa de “Banda de Reserva de Regulação” '"
            :field_type="'float'"
            :suffix="'€ / kWh'"
            :bold="true"
            :title_alone="false"
            @checkMethod="checkSubmitButton"
          />
        </v-col> -->
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="this.edit == true"
      :class="period == 1 ? 'pt-1' : ''"
      class="mx-0 my-0 py-0 px-0"
    >
      <v-col
        cols="12"
        :class="this.noMargins == true ? 'mx-0 px-0' : ''"
        v-if="this.edit_data[0].plan > 0"
      >
        <form-textbox
          :field_key="'power_price'"
          :period="periods"
          :error="error"
          @warning="watchWarning"
          :edit="true"
          :required="true"
          :title="'Indique o custo da potência em horas de ponta'"
          :field_type="'float'"
          :suffix="'€ / kVA'"
          :bold="true"
          :title_alone="false"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- Simples tariff value 1 -->
      <v-col
        cols="12"
        :class="
          (this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null)
        "
        class="pt-2 pb-0"
        v-if="
          this.edit_data[0].schedule_type == 0 && this.edit_data[0].plan == 0
        "
      >
        <form-textbox
          :edit="true"
          :field_key="'t2_energy_price'"
          :error="error"
          @warning="watchWarning"
          :period="periods"
          :required="true"
          :title="'Indique o valor da tarifa de energia contratada'"
          :placeholder="'Valor da tarifa'"
          :field_type="'float'"
          :suffix="'€ / kWh'"
          :bold="true"
          :title_alone="false"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- End simples tariff value 1 -->

      <!-- Title for Bi-horario, Tri-horario and !BTN value -->
      <v-col
        cols="12"
        :class="
          (this.noMargins == true ? 'mx-0' : '',
          this.checkMargins == true ? 'px-0' : null)
        "
        class="pt-2 pb-4"
        v-if="
          this.edit_data[0].schedule_type > 0 && this.edit_data[0].plan != 1
        "
      >
        <form-textbox
          :bold="true"
          :period="periods"
          :title_alone="true"
          @warning="watchWarning"
          :error="error"
          :required="true"
          :edit="true"
          :title="'Indique o valor das tarifas de Energia'"
          @checkMethod="checkSubmitButton"
        />
      </v-col>
      <!-- End title for Bi-horario and Tri-horario and !BTN value -->

      <!-- Bi-horario, Tri-horario and !BTN tariff values-->
      <v-col :class="this.noMargins == true ? 'px-0 pb-0' : 'pb-0'">
        <v-row>
          <v-col
            :class="this.noMargins == true ? 'mx-0' : ''"
            cols="12"
            v-if="this.edit_data[0].plan == 1"
            class="pt-0 pb-2 normal-label text-5"
            >Indique o valor das tarifas de Energia</v-col
          >
          <v-col
            cols="6"
            class="pt-4 pb-0"
            :class="this.noMargins == true ? 'mx-0' : ''"
            v-if="this.edit_data[0].plan > 0"
          >
            <form-textbox
              :edit="true"
              :field_key="'t1_energy_price'"
              :required="true"
              @warning="watchWarning"
              :error="error"
              :period="periods"
              :title="'Hora de Super-Vazio'"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              this.edit_data[0].plan == '0' &&
              this.edit_data[0].schedule_type == '2'
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            class="pt-4 pb-0"
            v-if="
              this.edit_data[0].schedule_type != 0 ||
              this.edit_data[0].plan != 0
            "
          >
            <form-textbox
              :edit="true"
              :field_key="'t2_energy_price'"
              :period="periods"
              :error="error"
              :title="'Hora de Vazio'"
              @warning="watchWarning"
              :required="true"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            cols="6"
            class="pt-4 pb-0"
            v-if="
              this.edit_data[0].schedule_type > 0 || this.edit_data[0].plan != 0
            "
          >
            <form-textbox
              :field_key="'t3_energy_price'"
              :period="periods"
              @warning="watchWarning"
              :required="true"
              :error="error"
              :title="
                this.edit_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio'
              "
              :edit="true"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            :class="[
              this.noMargins == true ? 'mx-0' : '',
              (this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2) ||
              this.edit_data[0].plan == null
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
            cols="6"
            class="pt-4 pb-0"
            v-if="
              this.edit_data[0].schedule_type == 2 ||
              this.edit_data[0].plan != 0
            "
          >
            <form-textbox
              :period="periods"
              :edit="true"
              :required="true"
              :error="error"
              @warning="watchWarning"
              :field_key="'t4_energy_price'"
              :title="'Hora de Ponta'"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
        </v-row>
        <v-row v-if="this.edit_data[0].plan == 1">
          <v-col
            :class="this.noMargins == true ? 'mx-0' : ''"
            cols="12"
            v-if="this.edit_data[0].plan == 1"
            class="pt-0 pb-2 normal-label text-5 mt-3"
            >Indique o valor das tarifas de Redes</v-col
          >
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-4' : ''">
            <form-textbox
              :edit="true"
              :period="periods"
              :required="true"
              :error="error"
              @warning="watchWarning"
              :field_key="'t1_grid_price'"
              :title="'Hora de Super-Vazio'"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col
            cols="6"
            :class="[
              (this.noMargins == true ? 'mx-0 pt-4' : '',
              this.contract_data[0].plan == 0 &&
                this.contract_data[0].schedule_type == 2) ||
              (this.noMargins == true ? 'mx-0 pt-4' : '',
              this.edit_data[0].plan == 0 &&
                this.edit_data[0].schedule_type == 2)
                ? 'col-sm-4 col-12'
                : 'col-6',
            ]"
          >
            <form-textbox
              :edit="true"
              :period="periods"
              :field_key="'t2_grid_price'"
              :error="error"
              @warning="watchWarning"
              :required="true"
              :title="'Hora de Vazio'"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-0' : ''">
            <form-textbox
              :edit="true"
              :period="periods"
              :field_key="'t3_grid_price'"
              :error="error"
              :title="
                this.edit_data[0].schedule_type == 1
                  ? 'Hora de Fora-Vazio'
                  : 'Hora de Cheio'
              "
              :field_type="'float'"
              @warning="watchWarning"
              :suffix="'€ / kWh'"
              :bold="false"
              :required="true"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <v-col cols="6" :class="this.noMargins == true ? 'mx-0 pt-0' : ''">
            <form-textbox
              :edit="true"
              :period="periods"
              :field_key="'t4_grid_price'"
              :error="error"
              @warning="watchWarning"
              :title="'Hora de Ponta'"
              :required="true"
              :field_type="'float'"
              :suffix="'€ / kWh'"
              :bold="false"
              :title_alone="false"
              @checkMethod="checkSubmitButton"
            />
          </v-col>
          <!-- <v-col cols="12" :class="this.noMargins == true ? 'mx-0' : ''">
          <form-textbox
            :period="periods"
            :field_key="'mec_ajuste'"
            :title="'Indique o valor da tarifa de “Mecanismo de Ajuste“ '"
            :field_type="'float'"
            :suffix="'€ / kWh'"
            :bold="true"
            :title_alone="false"
            @checkMethod="checkSubmitButton"
          />
        </v-col>
        <v-col cols="12" :class="this.noMargins == true ? 'mx-0' : ''">
          <form-textbox
            :period="periods"
            :field_key="'banda_reg'"
            :title="'Indique o valor da tarifa de “Banda de Reserva de Regulação” '"
            :field_type="'float'"
            :suffix="'€ / kWh'"
            :bold="true"
            :title_alone="false"
            @checkMethod="checkSubmitButton"
          />
        </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>



<script lang="ts">
import { General } from "@/mixins/general";
import Vue from "vue";
import FormTextbox from "@/components/forms/form-textbox.vue";
import mixins from "vue-typed-mixins";

export default mixins(General).extend({
  name: "FillTariffs",
  components: { FormTextbox },

  data() {
    return {
      warning: false,
    };
  },
  props: ["error", "checkSubmitButton", "noMargins", "edit", "checkMargins"],
  methods: {
    watchWarning(val: any) {
      this.warning = val;
      this.$emit("warning", val);
    },
  },
});
</script>

<style lang="scss" scoped></style>
