<template>
  <v-row>
    <v-col cols="12" class="header-3 font-weight-bold text-left"
      >Apoio ao cliente</v-col
    >
    <v-col cols="12" class="text-start pt-0 pb-0"
      >Caso tenha alguma dúvida relativamente ao EDGE, verifique o nosso suporte
      rápido ou entre em contacto connosco para que o possamos ajudar.</v-col
    >
    <v-col cols="12" class="pt-0">
      <faq-mode @callEvent="resetToggles" />
    </v-col>
    <!--FAQ's -->
    <v-col cols="12" v-if="this.active_tab_faq == 0">
      <v-card class="rounded-lg px-3 mb-9">
        <v-row class="top-text">
          <v-col cols="12" class="font-weight-bold text text-5 pb-0 pl-4">
            Selecione uma categoria
          </v-col>
          <v-col cols="12" class="pl-4">
            <form-select
              :field_type="'string'"
              :field_key_faq="'category'"
              :items="this.titles"
              @trackCat="getCat"
              :placeholder="'Selecione'"
            />
          </v-col>
        </v-row>
        <!-- Ensure it isnt displayed in the beginning-->
        <div v-if="faqsListIndex != -1">
          <v-row
            class="pt-3 mt-0"
            v-for="(cat, index) in this.faqsList[faqsListIndex].sub_cat"
            :key="index"
          >
            <v-col class="pl-4 d-flex align-center font-weight-bold header-5">{{
              cat.title
            }}</v-col>
            <v-col class="d-flex justify-end pr-4"
              ><v-icon
                size="40"
                @click="toggleRotation(index, true)"
                color="black"
                :class="
                  isRotated && index == catSelected ? 'rotated' : 'revert'
                "
                >mdi-chevron-right
              </v-icon></v-col
            >
            <v-col
              cols="12"
              v-if="isRotated && filteredFaqList && index == catSelected"
              :class="isRotated ? 'slide-down' : 'slide-up'"
            >
              <v-row
                v-for="(item, index) in filteredFaqList"
                :key="index"
                class="px-4"
              >
                <v-col
                  class="px-0 pb-0 font-weight-medium d-flex align-center"
                  cols="10"
                  >{{ item.title }}</v-col
                >
                <v-col cols="2" class="px-0 pb-0 d-flex justify-end"
                  ><button
                    @click="chooseTab(index)"
                    class="sign d-flex align-center justify-center"
                  >
                    <img
                      :src="
                        faqItem != index
                          ? require('@/assets/icons/plus-sign.svg')
                          : require('@/assets/icons/minus-sign.svg')
                      "
                    />
                  </button>
                </v-col>
                <v-col
                  v-html="item.text"
                  class="px-0"
                  cols="12"
                  v-if="faqItem === index"
                ></v-col>

                <v-col v-if="faqItem === index" cols="12" class="pt-6 px-0">
                  <bill-notification
                    :header="'Esta resposta ajudou?'"
                    :text="textNotification"
                  />
                </v-col>
                <v-col class="px-3 pt-0 mx-0 bottom-liner" cols="12"></v-col>
              </v-row>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-col>
    <!--End FAQ's-->
    <!-- Abrir Ticket-->
    <v-col cols="12" v-if="this.active_tab_faq == 1" class="px-0 py-0">
      <v-row class="mx-0 my-0 px-0 py-0">
        <v-col cols="12" class="mx-0 my-0">
          <form-select
            :field_key_faq="'category'"
            :title="'Selecione a categoria '"
            :items="categories"
            @trackCat="getCat"
            :placeholder="'Escolha uma opção'"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0 my-0 px-0 py-0">
        <v-col cols="12" class="mx-0 my-0">
          <form-select
            :disabled="this.faq_data.category != null ? false : true"
            :field_key_faq="'sub_category'"
            :title="'Selecione a sub-categoria'"
            :items="subCategorySelected"
            :placeholder="'Escolha uma opção'"
          />
        </v-col>
      </v-row>

      <v-row class="mx-0 my-0 px-0 py-0">
        <v-col cols="12" class="mx-0 my-0">
          <form-textarea
            :bold="true"
            :disabled="this.faq_data.sub_category != null ? false : true"
            :field_key_faq="'message'"
            :title="'Descreva o problema'"
            :placeholder="'Escreva aqui o seu texto'"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0 my-0 px-0 py-0" style="padding-bottom: 1px !important;">
        <v-col cols="12" class="mx-0 my-0">
          <form-photo-upload
            :opt="true"
            :bold="true"
            :disabled="this.faq_data.sub_category != null"
            :field_key_faq="'fotos'"
            :title="'Anexe fotografias para que possamos identificar o problema'"
          />
        </v-col>
      </v-row>
      <v-row class="mx-0 mt-6 mb-6 px-0 py-0">
        <v-col cols="12" class="mx-0 my-0">
          <v-btn
            :disabled="getDisabled()"
            :loading="loading"
            @click="submitHelp"
            :name="'update-tariff-info'"
            color="#47aea5"
            rounded-md
            block
          >
            <div
              :class="getDisabled() == true ? 'disabled-text' : ''"
              class="button button-text-1"
            >
              Enviar
            </div>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="mx-0 mb-6 my-0 px-0 py-0">
        <v-col cols="12" class="mx-0 my-0">
          <div class="mb-4">
            <span class="phoneNumberStyling" style="font-weight: bold" >Contacto telefónico</span>
          </div>
          <span class="phoneNumberStyling">
            O nosso atendimento telefónico está disponível de segunda a sexta-feira das 09 às 18 horas através do:
          </span>
          <div class="mt-4">
            <a href="tel:210148734" style="font-size: 20px ; text-decoration: underline; color: inherit; font-weight: bold;">
              <img src="@/assets/icons/phone.svg" alt="Phone Icon" style="margin-right: 4px; width: 16px; height: 16px; vertical-align: middle;"/>210 148 734
            </a>
          </div>
        </v-col>
      </v-row>


    </v-col>
    <success-modal
      :modalName="'send-support-request'"
      :alt="'send support success'"
      :icon="'Union.svg'"
      :header="'Pedido de Contacto Submetido'"
      :text="'O seu pedido de ajuda foi enviado e vai ser analisado em breve pela nossa equipa técnica. '"
    />
    <!--End Abrir Ticket-->
    <!--Documentos Manager View -->
    <v-col v-if="this.active_tab_faq == 2">
      <div>
        <v-card class="rounded-lg px-3 mb-9">
          <div>
            <v-row class="mt-0">
              <v-col class="pl-4 d-flex align-center font-weight-bold header-5">Manuais</v-col>
              <v-col class="d-flex justify-end pr-4">
                <v-icon
                  size="40"
                  @click="toggleRotationDocumentacion(1)"
                  color="black"
                  :class="isRotatedDocumentacion[1] ? 'rotated' : 'revert'"
                >
                  mdi-chevron-right
                </v-icon>
              </v-col>
              <v-col
                cols="12"
                v-if="isRotatedDocumentacion[1]"
                :class="isRotatedDocumentacion[1] ? 'slide-down' : 'slide-up'"
              >
                <div
                  class="doc-card px-0 py-0"
                  v-for="(item, index) in documents"
                  :key="index"
                >
                  <v-col class="d-flex align-center pt-3 justify-space-between">
                    <div class="col-8 d-flex align-center">
                      <img
                        class="pr-3"
                        :src="
                          item.type == 'doc'
                            ? require('@/assets/icons/doc.svg')
                            : require('@/assets/icons/pdf.svg')
                        "
                      />
                      <span>{{ item.title }}</span>
                    </div>
                    <submit-button
                      style="padding-top: 10px;"
                      @callEvent="download(item.download_url)"
                      :width="100"
                      :height="35"
                      :title="'Consultar'"
                      :textSize="true"
                    />
                  </v-col>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card>
          <v-card class="rounded-lg px-3 mb-9">
    <div>
      <v-row class="mt-0">
        <v-col class="pl-4 d-flex align-center font-weight-bold header-5">Vídeo</v-col>
        <v-col class="d-flex justify-end pr-4">
          <v-icon
            size="40"
            @click="toggleRotationDocumentacion(2)"
            color="black"
            :class="isRotatedDocumentacion[2] ? 'rotated' : 'revert'"
          >
            mdi-chevron-right
          </v-icon>
        </v-col>
        <v-col
          cols="12"
          v-if="isRotatedDocumentacion[2]"
          :class="isRotatedDocumentacion[2] ? 'slide-down' : 'slide-up'"
        >
          <div class="doc-card px-0 py-0">
            <VideoPreview :videoSrc="getVideoUrl()" />
          </div>
        </v-col>
      </v-row>
    </div>
  </v-card>
      </div>
    </v-col>
  </v-row>
  
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import FaqMode from "@/components/dashboard/faq-mode.vue";
import { General } from "@/mixins/general";
import FormSelect from "@/components/forms/form-select.vue";
import FormTextarea from "@/components/forms/form-textarea.vue";
import FormRadio from "@/components/forms/form-radio.vue";
import FormPhotoUpload from "@/components/forms/form-photo-upload.vue";
import SubmitButton from "@/components/dashboard/submit-button.vue";
import VideoPreview from '@/components/dashboard/video-preview.vue';
import SuccessModal from "@/components/general/success-modal.vue";
import BillNotification from "@/components/dashboard/bill-notification.vue";
export default mixins(General).extend({
  components: {
    FaqMode,
    FormSelect,
    FormTextarea,
    FormRadio,
    FormPhotoUpload,
    SubmitButton,
    SuccessModal,
    BillNotification,
    VideoPreview,
  },
  name: "Support",

  data() {
    return {
      isRotatedDocumentacion: {} as { [key: number]: boolean },
      selectedVal: null as any,
      category: -1 as any,
      catSelected: 0 as any,
      loading: false,
      isRotated: false,
      textNotification:
        'Pedimos-lhe que entre em contacto com o nosso Suporte Técnico, através do formulário que se encontra na área de <strong>"Contacto"</strong>.',

      documents: [
        {
          type: "pdf",
          title: "Manual do Utilizador",
          download_url: "https://dwb637uj4i4a3.cloudfront.net/general_docs/manual_do_utilizador.pdf"
        },
        {
          type: "doc",
          title: "Manual do Produto",
          download_url: "https://dwb637uj4i4a3.cloudfront.net/general_docs/manual_do_produto.pdf"
        },
      ],
      faqItem: null as any,
    };
  },
  created() {
    this.$store.dispatch("getFaqData");
    if (this.$route.query.cat) {
      let data;
      if (this.condos_response.selected_role == "user") {
        data = { key: "category", value: "5" };
        this.$store.commit("updateFaqInfo", data);
        this.faqItem = 4;
      } else {
        data = { key: "category", value: "1" };
        this.$store.commit("updateFaqInfo", data);
        this.faqItem = 5;
      }

      this.initializeValueBasedOnRoute();
      this.getCat(0);
      this.toggleRotation(0, true);
    }
  },

  methods: {
    download(download_url: any) {
      fetch(download_url,{mode: 'no-cors'})
        .then(response => response.blob())  // Convert response to blob
        .then(blob => {
            const url = window.URL.createObjectURL(blob);
            // Create a temporary <a> element to trigger the download
            const a = document.createElement('a');
            a.href = url;
            const urlParts = download_url.split('/');
            const filename = urlParts[urlParts.length - 1];
            a.download = filename;  // Specify the filename here
            document.body.appendChild(a);
            // Initiate the download
            a.click();
            // Clean up
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        })
      .catch(error => {
          console.error('Error downloading file:', error);
      });
    },
    initializeValueBasedOnRoute() {
      // Check the route, and if it matches your criteria, initialize the value
      if (this.$route.query.cat == "1") {
        this.selectedVal = 0; // Or any other initialization logic based on the route
      }
    },

    resetToggles() {
      this.isRotated = false;
      this.catSelected = 0;
      this.faqItem = null;
    },
    getVideoUrl(){
      if(this.condos_response.selected_role == "user"){
        return 'https://player.vimeo.com/video/981813059?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
      }else{
        return 'https://player.vimeo.com/video/981814668?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479';
      }
    },

    getTicketClass(state: string): string {
      if (state == "Em Análise") {
        return "analise";
      } else {
        return "todos";
      }
    },

    getDisabled(): boolean {
      if (
        this.loading ||
        this.faq_data.category == "" ||
        this.faq_data.category == null ||
        this.faq_data.message == "" ||
        this.faq_data.message == null ||
        this.faq_data.sub_category == null
      ) {
        return true;
      } else return false;
    },

    toggleRotation(index: any, status: boolean): any {
      //For if the previous state is rotated and we select another we need to be able to close that one
      if (this.isRotated && this.catSelected == index) {
        this.isRotated = false;
      } else {
        this.catSelected = this.$route.query.cat ? 0 : index;
        this.isRotated = this.$route.query.cat ? true : status;
      }
    },
    toggleRotationDocumentacion(index: number) {
      this.$set(this.isRotatedDocumentacion, index, !this.isRotatedDocumentacion[index]);
    },
    getCat(val: any) {
      this.catSelected =
        this.faq_data.category != null ? parseInt(this.faq_data.category) : -1;
      const user_to =
        this.condos_response.selected_role == "manager"
          ? "administrator"
          : "user";

      const index = this.faq_text_value[user_to]
        ? this.faq_text_value[user_to].findIndex(
            (item: any, index: number) => index == val
          )
        : null;
      this.category = parseInt(index);
    },
    chooseTab(tab_index: any) {
      if (tab_index == this.faqItem) {
        this.faqItem = null;
      } else {
        this.faqItem = tab_index;
      }
    },

    async submitHelp() {
      let formData = new FormData();
      this.loading = true;

      for (let i = 0; i < this.faq_data.document_upload.length; i++) {
        formData.append("document_upload", this.faq_data.document_upload[i]);
      }
      formData.append("message", this.faq_data.message);
      formData.append("category", this.faq_data.category);
      formData.append("sub_category", this.faq_data.sub_category);
      formData.append("email", "test@email.com");

      this.$store
        .dispatch("requestSupport", formData)
        .then((response: any) => {
          this.loading = false;
          if (response != undefined) {
            this.$store.commit("toggleModalState", {
              status: true,
              name: "send-support-request",
            });
          }
        })
        .then(() => {
          this.$store.commit("resetFaqData");
        });
    },

    getFaqs(index: any) {
      return this.faqsList[this.category_selected_index].sub_cat[index];
    },
  },

  computed: {
    faqsListIndex(): any {
      const categories = this.categories;

      if (categories != undefined) {
        return categories.findIndex(
          (item: any) =>
            parseInt(item.value) == parseInt(this.faq_data.category)
        );
      } else {
        this.category = 0;
        this.catSelected = 0;
        return -1;
      }
    },

    category_selected_index: {
      get() {
        if (this.condos_response.selected_role) {
          const categories = this.categories as any;
          if (categories != undefined) {
            return categories.findIndex(
              (item: any) =>
                parseInt(item.value) == parseInt(this.faq_data.category)
            );
          } else if (this.$route.query.cat == "1") {
            return 1;
          } else return -1;
        }
      },
      set(value: any) {
        this.selectedVal = value;
      },
    },

    faqsList(): any {
      const user_to =
        this.condos_response.selected_role == "manager"
          ? "administrator"
          : "user";

      return this.faq_text_value[user_to];
    },
    categories(): any {
      const user_to =
        this.condos_response.selected_role == "manager"
          ? "administrator"
          : "user";

      return this.faq_text_value[user_to]
        ? this.faq_text_value[user_to].map((item: any, index: any) => ({
            value: String(item.id),
            text: String(item.title),
          }))
        : null;
    },

    subCategorySelected(): any {
      const user_to =
        this.condos_response.selected_role == "manager"
          ? "administrator"
          : "user";

      return this.category_selected_index != -1
        ? this.faq_text_value[user_to][
            this.category_selected_index
          ].sub_cat.map((item: any, index: any) => ({
            value: String(item.id),
            text: String(item.title),
          }))
        : null;
    },
    filteredFaqList(): Array<any> {
      return this.faqsList[this.category_selected_index].sub_cat[
        this.catSelected
      ]
        ? this.faqsList[this.category_selected_index].sub_cat[this.catSelected]
            .faq
        : null;
    },

    //contains all sections
    titles(): Array<any> {
      return this.faqsList
        ? this.faqsList.map((item: any, index: any) => ({
            value: String(item.id),
            text: String(item.title),
          }))
        : null;
    },
  },
});
</script>
<style scoped lang="scss">
.top-text {
  background-color: $gray-1;
  color: white;
}

.blue-text {
  color: $blue-1;
}

.analise {
  border-radius: 50px;
  color: #47aea5;
  background-color: rgba(71, 174, 165, 0.2);
  border: 2px solid #47aea5;
  max-width: 103px !important;
  font-size: 14px;
  min-height: 35px;
}

.sign {
  background-color: #47aea5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
}

@keyframes slideDown {
  from {
    transform: translateY(-25%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 100%;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

.slide-down {
  animation: slideDown 0.2s forwards;
}

@keyframes rotate90 {
  to {
    transform: rotate(90deg);
  }
}

@keyframes Nrotate90 {
  from {
    transform: rotate(90deg);
  }

  to {
    transform: rotate(0deg);
  }
}

.disabled-text {
  color: #b1b1b1 !important;
}

.revert {
  animation-name: Nrotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.rotated {
  animation-name: rotate90;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
}

.doc-card {
  background-color: white;
  border-radius: 5px;
}

.v-application .primary--text {
  color: rgba(0, 0, 0, 0.54) !important;

  caret-color: rgba(0, 0, 0, 0.54) !important;
}

.v-messages {
  display: none;
}

.phoneNumberStyling{
  font-size: 18px;
}

</style>
