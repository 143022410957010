<template>
  <v-row>
    <v-col v-if="title" cols="12" class="text text-5 normal-label pb-0 pt-0">
      {{ title }}
    </v-col>
    <v-col col="12" class="pt-1">
      <v-select
        v-if="
          $route.path != '/dashboard/support' &&
          type != 'Box' &&
          this.edit != true
        "
        :items="items"
        :placeholder="placeholder"
        outlined
        dense
        height="45"
        background-color="#ffffff"
        item-color="#2e3335"
        v-model="field_value"
        hide-details
        v-on:change="callMethod"
        class="filled-input"
      >
      </v-select>
      <v-select
        v-if="
          $route.path != '/dashboard/support' &&
          type != 'Box' &&
          this.edit == true
        "
        :items="items"
        :placeholder="placeholder"
        outlined
        dense
        height="45"
        background-color="#ffffff"
        item-color="#2e3335"
        v-model="edit_value"
        hide-details
        v-on:change="callMethod"
        class="filled-input"
      >
      </v-select>
      <v-select
        :disabled="this.disabled"
        v-if="$route.path == '/dashboard/support'"
        :items="items"
        :placeholder="placeholder"
        outlined
        dense
        height="45"
        :background-color="this.disabled == true ? '#DFDFDF' : '#ffffff'"
        @input="check"
        item-color="#2e3335"
        v-model="faq_value"
        hide-details
        v-on:change="callMethod"
        class="filled-input"
      >
      </v-select>
      <v-select
        v-if="type == 'Box'"
        :multiple="type == 'Box' ? true : false"
        :items="items"
        :placeholder="placeholder"
        outlined
        dense
        height="45"
        background-color="#ffffff"
        item-color="#2e3335"
        v-model="condo_value"
        hide-details
        v-on:change="callMethod"
        class="filled-input"
      >
        <template v-slot:append v-if="type == 'Box' ? true : false">
          <img
            src="@/assets/icons/hub-icon.svg"
            alt="Custom Icon"
            class="custom-icon"
          />
        </template>
      </v-select>
    </v-col>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";

export default mixins(General).extend({
  name: "FormSelect",
  props: ["title", "edit", "items", "placeholder", "disabled", "type"],
  data() {
    return {
      value: "",
    };
  },
  methods: {
    check(e: any) {
      this.$emit("trackCat", e);
    },
  },
});
</script>

<style lang="scss" scoped>
.select-field {
  color: #c9c9c9 !important;
}

.mdi-menu-down::before {
  color: rgba(0, 0, 0, 0.54) !important;
  caret-color: rgba(0, 0, 0, 0.54) !important;
}
</style>
