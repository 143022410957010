<template>
  <v-dialog
    v-model="modalShow"
    content-class="simple-content-modal overflow-hidden rounded-lg external mx-5 mb-6"
    max-width="801px"
  >
    <div class="top-nav d-flex">
      <p class="select-period my-4 mx-5 d-flex align-center font-weight-bold">
        Selecionar Período de Faturação
      </p>

      <v-icon
        color="white"
        class="ml-auto align-center mr-4 close-modal"
        @click="closeModal(modalName)"
        >mdi-close
      </v-icon>
    </div>
    <v-card
      style="overflow: auto"
      class="container-calendar d-flex flex-column align-center"
    >
      <div class="calendar-picker d-flex">
        <calendar-picker
          :modalName="modalName"
          :billingStartDate="billingStartDate"
          :billingEndDate="billingEndDate"
        />
      </div>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "../../mixins/general";
import CalendarPicker from "./calendar-picker.vue";

export default mixins(General).extend({
  name: "DateModal",
  props: ["modalName", "billingStartDate", "billingEndDate"],
  components: {
    CalendarPicker,
  },
  computed: {
    modalShow: {
      get(): string {
        return this.$store.getters["modalState"](this.modalName);
      },
      set() {
        this.closeModal(this.modalName);
      },
    },
  },
});
</script>

<style lang="scss" scoped>
.calendar-modal {
  border-radius: 104px;
}

.container-calendar {
  overflow-y: hidden !important;
}

.calendar-picker {
  width: 100%;
}

.simple-content-modal {
  overflow: hidden !important;
  background-color: rgba(107, 107, 107, 0.293);
  max-height: 95% !important;
}

.top-nav {
  background-color: $gray-1;
  width: 100%;
  height: 60px;
  font-size: 18px;
}

@media only screen and (max-width: 375px) {
  .top-nav {
    height: 50px;
  }
}

@media only screen and (max-width: 390px) {
  .top-nav {
    font-size: 17px;
    height: 50px;
  }
}

@media only screen and (max-width: 390px) {
  .top-nav {
    font-size: 17px;
  }
}

.select-period {
  color: #fff;
}

.close-modal:focus::after {
  opacity: 0 !important;
}
</style>
