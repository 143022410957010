<template>
  <v-row class="mt-1 mb-6">
    <v-col cols="12" class="text text-5 normal-label pt-0 pb-0"
      >Selecione o ciclo temporal das tarifas</v-col
    >
    <div class="d-flex" style="width: 100%" v-if="this.edit != true">
      <v-col class="pr-0">
        <v-btn
          class="button cycle-inactive first button-text-2 tab-round-left"
          :class="{
            'cycle-active': this.contract_data[0].cycle == 0,
          }"
          v-on:click="changeCycle(0)"
          block
        >
          Diário
        </v-btn>
      </v-col>
      <v-col class="px-0">
        <v-btn
          class="button cycle-inactive second button-text-2 tab-round-none"
          :class="{
            'cycle-active': this.contract_data[0].cycle == 1,
          }"
          v-on:click="changeCycle(1)"
          block
        >
          Semanal
        </v-btn>
      </v-col>
      <v-col class="pl-0">
        <v-btn
          disabled
          elevation="0"
          class="button cycle-inactive disabled third button-text-2 tab-round-right"
          :class="{
            'cycle-active': this.contract_data[0].cycle == 2,
          }"
          v-on:click="changeCycle(2)"
          block
          plain
        >
          Opcional
        </v-btn>
      </v-col>
    </div>
    <div class="d-flex" style="width: 100%" v-if="this.edit == true">
      <v-col class="pr-0">
        <v-btn
          class="button cycle-inactive first button-text-2 tab-round-left"
          :class="{
            'cycle-active': this.edit_data[0].cycle == 0,
          }"
          v-on:click="changeCycle(0)"
          block
        >
          Diário
        </v-btn>
      </v-col>
      <v-col class="px-0">
        <v-btn
          class="button cycle-inactive second button-text-2 tab-round-none"
          :class="{
            'cycle-active': this.edit_data[0].cycle == 1,
          }"
          v-on:click="changeCycle(1)"
          block
        >
          Semanal
        </v-btn>
      </v-col>
      <v-col class="pl-0">
        <v-btn
          disabled
          elevation="0"
          class="button cycle-inactive disabled third button-text-2 tab-round-right"
          :class="{
            'cycle-active': this.edit_data[0].cycle == 2,
          }"
          v-on:click="changeCycle(2)"
          block
          plain
        >
          Opcional
        </v-btn>
      </v-col>
    </div>
  </v-row>
</template>

<script lang="ts">
import mixins from "vue-typed-mixins";
import { General } from "@/mixins/general";
export default mixins(General).extend({
  name: "TariffCycle",
  methods: {
    changeCycle(cycle_index: number): void {
      if (this.edit != true) {
        this.$store.dispatch("changeFieldValue", {
          key: "cycle",
          value: cycle_index,
          period: this.period_edit,
        });
      } else if (this.edit == true) {
        this.$store.dispatch("changeEditValue", {
          key: "cycle",
          value: cycle_index,
          period: this.period_edit,
        });
      }

      this.callMethod();
    },
  },
  props: ["edit", "testprop"],
});
</script>

<style scoped  lang="scss" >
.cycle-inactive {
  width: 100%;
  box-shadow: none !important;
  background-color: #ffffff !important;
  border: solid 1px $blue-1;
  height: 50px !important;
  color: $blue-1;
  font-weight: normal;
  &.disabled {
    border: solid 1px #d8d8d8;
    border-left: none;
    background-color: #d8d8d8;
    color: #b1b1b1;
  }
  &.first {
    border-right: solid 0.5px $blue-1;
  }
  &.second {
    border-left: solid 0.5px $blue-1;
    border-right: solid 0.5px $blue-1;
  }
}

.cycle-active {
  background-color: $blue-1 !important;
  color: $white-1;
  font-weight: bold;
}
</style>