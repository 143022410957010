import axios from "axios";
import config from "./config";
import store from "@/store";
import router from "@/router";

const baseUrl = config.apiUrl;

const setCookie = (name: string) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }

  sessionStorage.setItem(name, cookieValue!);
};

const getCookie = (name: string) => {
  return sessionStorage.getItem(name) ? sessionStorage.getItem(name) : "";
};

const customAxios = axios.create({
  baseURL: baseUrl,
  timeout: 10000,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

const requestHandler = (request: any) => {
  const token = store.getters.currentToken;
  if (token) {
    request.headers.Authorization = `Bearer ${token.access}`;
  }
  const csrftoken = getCookie("csrftoken");
  if (csrftoken) {
    request.headers["X-CSRFToken"] = csrftoken;
  }
  return request;
};

const responseHandler = (response: any) => {
  // response.headers['set-cookie']
  setCookie("csrftoken");
  return response;
};

const errorHandler = async (error: any) => {//to do mudar os if porque n esta a funcionar esta parte
  const originalRequest = error.config;
  if (!originalRequest.url.includes("api/token/")) {
    if (
      error.response ? (error.response.status === 401) : (error.request.status == 0) &&
      originalRequest.url.includes("api/token/refresh/")
    ) {
      store.commit("updateToken", null);
      router.replace("/login");
      return Promise.reject(error);
    } else if (error.response ? (error.response.status === 401) : (error.request.status == 0) && !originalRequest._retry) {
      originalRequest._retry = true;
      const res = await store.dispatch("refreshToken");
      if (res) {
        return customAxios(originalRequest);
      } else {
        router.replace("/login");
        return Promise.reject(error);
      }
    }
  }
  return Promise.reject(error);
};

customAxios.interceptors.request.use(
  (request) => requestHandler(request),
  (error) => errorHandler(error)
);

customAxios.interceptors.response.use(
  (response) => responseHandler(response),
  (error) => errorHandler(error)
);

export default customAxios;
